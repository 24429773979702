import './App.css';
import styled from 'styled-components';
import axios, {isCancel, AxiosError} from 'axios';
import { useId, useState } from 'react';
import {Buffer} from 'buffer';


const Button = styled.button`
  background: transparent;
  border-radius: 3px;
  border: 2px solid #b6172b;
  color: #b6172b;
  margin: 0 1em;
  padding: 0.25em 1em;
`

const Input = styled.input`
  padding: 0.5em;
  margin: 0.5em;
  background: "#b6172b";
  color: "#b6172b";
  border: none;
  border-radius: 3px;
`;

const Ad = styled.a`
  font-size: calc(10px + 1vmin);
  color: #b6172b;
`

const Spinner = styled.img`
  align: center;
  width: 50px;
  height: 50px;
`

function App() {

  const id = useId();
  const [input, setInput] = useState('');
  const [dataBlob, setDataBlob] = useState('');
  const [ingenSopa, setIngenSopa] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);

  const onTextListener = (e) => {
    setInput(e.target.value);
    setDataBlob('');
  }

  const onClickListener = () => {
    setDataBlob(null)
    setIngenSopa(null)
    setShowSpinner(true)
   // Show spinner
    axios.get('/soptomning',
              { headers: {'accept': 'text/calendar'},
                params: { address: input }})
          .then( successResponse => {
             // Hide spinner
             setShowSpinner(false);
             // Make data link
             setDataBlob(Buffer.from(successResponse.data).toString('base64'))

          }).catch( errorResponse => {
             console.log(errorResponse)
             // Hide spinner
             setShowSpinner(false);
             // visa d;dskallen
             setIngenSopa(input)

          })
  }

  function ShowInput(){
      if (/^[\wåÅäÄöÖ]+(:?[-\s][\wåÅäÄöÖ]+)?\s+\d+$/.test(input)){
        return <Button onClick={() => onClickListener()} >Klicka sedan på knappen. 👍</Button>
      }
  }

  function ShowResult(){

    if (dataBlob){
       return <Ad href={`data:text/calendar;base64,${dataBlob}`}>Klicka på här för att tanka ner soptömningen till din calendar app.</Ad>
    } else if (ingenSopa){
      return "Tyvärr kunde ingen sophämtning lokaliseras på denna address... :("
    }
  }

  return (
    <div className="App">
      <header className="App-header">
          <div>
            <label htmlFor={id}>Mata in addressen (i ånge kommun) i fältet här.</label>
            <Input type="text" name="address" value={input} props={input} id={id} onChange={e => onTextListener(e)} />
          </div>
          <div>
            <ShowInput/>
          </div>
          <div>
            <Spinner src="spinner.gif" hidden={!showSpinner}/>
            <ShowResult/>
          </div>
      </header>
    </div>
  );
}

export default App;